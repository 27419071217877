<template>
  <div :class="[isFixed?'fixed':'']" class="header-box">
    <div v-if="!isFixed" class="header-inner">
        <img @click="toHome" src="@/assets/images/logo1.png" class="logo">
        <ul class="index-list">
            <li class="active">首页</li>
            <li @click="toSelf">关于我们</li>
        </ul>
    </div>
    <div v-else class="header-inner">
        <img @click="toHome" src="@/assets/images/logo1.png" class="logo">
        <div class="input-box-wrap d_flex_c_c">
          <div class="input-box-inner">
            <input type="text" v-model="cmpName" placeholder="请输入企业名称/代码号/标签" class="input-box" @keyup.enter="search"/>
          </div>
          <div @click="search" class="search-btn d_flex_c_c">
            <span class="iconfont iconsousuo1"></span>
          </div>
          <ul v-if="companyList.length>0" class="search-result">
            <li v-for="item in companyList" @click="toDetail(item.id)" :key="item.id">
              <img :src="item.logoimage || Setting.assetsUrl + 'assets/img/logo.png'" class="c_logo">
              <div v-html="item.name"></div>
            </li>
          </ul>
        </div>
        <ul class="index-list">
            <li @click="toSelf">关于我们</li>
        </ul>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import Setting from '@/setting';
import request from '../request';

export default {
  props:{
    isFixed:{
      default:true,
    }
  },
  data() {
    return {
      Setting,
      cmpName:'',
      companyList:[],
    }
  },
  methods:{
    //...mapActions(['getQueryCompany']),
    toSelf(){
      this.$router.push('/self')
    },
    toHome(){
      console.log(this.$route)
      if(this.$route.path !== '/'){
        this.$router.replace('/')
      }
    },
    toDetail(id){
      this.cmpName = ''
      this.$router.push(`/detail/${id}`)
    },
    search(){
      if(!this.cmpName.trim()) return;
      this.$router.push(`/list/${this.cmpName}`)
      this.cmpName = ''
    },
  },
  watch:{
    async cmpName(newVal){
      if(newVal){
        // let res = await this.getQueryCompany({
        //   keyword:newVal,
        // })
        // this.companyList = res.data
        const x2 = await request({ url: 'api/fa_company/search/' + encodeURI(newVal), method: 'get' });
        if (x2) {
          this.companyList = x2;
        }
      }else{
        this.companyList = []
      }
    },
  }
};
</script>
<style scoped lang="less">
.header-box {
  position: absolute;
  top: 0;
  min-width: 1200px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  & > .header-inner {
    width: 1200px;
    height: 86px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: space-between;
    &>.index-list{
        display: flex;
        font-size: 18px;
        color: #fff;
        &>li{
            padding: 15px 0;
            margin-left: 55px;
            cursor: pointer;
            &.active{
               border-bottom: 3px solid #3C9BD8; 
            }
            &:hover{
              color: #0F83FF;
            }
        }
    }
    &>.logo{
        height: 46px;
        cursor: pointer;
    }
  }
    &.fixed{
      position: fixed;
      top: 0;
      background-color: #fff;
      box-shadow: 0 5px 10px rgba(0,0,0,.05);
      & > .header-inner {
        .input-box-wrap{
          position: relative;
          &>.search-result{
            position: absolute;
            width:100%;
            left: 0;
            top: 62px;
            border: 1px solid #999;
            color: #333;
            font-size: 18px;
            z-index: 4;
            &>li{
              height: 45px;
              display: flex;
              align-items: center;
              padding: 0 20px;
              cursor: pointer;
              border-bottom: 1px solid #eee;
              background-color: #fff;
              &:last-child{
                border-bottom: 0;
              }
              &:hover{
                background-color: #eee;
              }
              .c_logo{
                width: 30px;
                height: 30px;
                margin-right: 10px;
              }
            }
          }
        }
        .input-box-inner{
          width: 620px;
          height: 62px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          color: #333;
          border: 1px solid #0F83FF;
          &>.input-box{
            flex: 1;
            font-size: 18px;
          }
        }
        .search-btn{
          width: 70px;
          height: 62px;
          background-color: #0F83FF;
          cursor: pointer;
          .iconsousuo1{
            font-size: 40px;
            color: #fff;
          }
        }
        &>.index-list{
            display: flex;
            font-size: 18px;
            color: #333;
            &>li{
                padding: 15px 0;
                margin-left: 55px;
                cursor: pointer;
                &:hover{
                  color: #0F83FF;
                }
            }
        }
        &>.logo{
            height: 46px;
        }
    }
  }
}
</style>