<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import Config from './setting';
export default {
  name: 'App',
  mounted(){
    this.$store.commit('setConfig', Config);
    console.log(Config);
  }
}
</script>