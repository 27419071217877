import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/reset.css'
import axios from '@/request'
import store from '@/store'
import * as api from '@/request/api'

Vue.config.productionTip = false
Vue.prototype.$ajax = axios;
Vue.prototype.$api = api;
Vue.use(ElementUI);
Date.prototype.format = function(format) {
	var o = {
		"M+": this.getMonth()+1, //month
		"d+": this.getDate(), //day
		"h+": this.getHours(), //hour
		"m+": this.getMinutes(), //minute
		"s+": this.getSeconds(), //second
		"q+": Math.floor((this.getMonth() + 3) / 3), //quarter
		"S": this.getMilliseconds() //millisecond
	}
	if(/(y+)/i.test(format)) {
		format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for(var k in o) {
		if(new RegExp("(" + k + ")").test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
		}
	}
	return format;
}

Vue.filter('ymdTimeFilter1',(value)=>{
  return new Date(value).format('yyyy-MM-dd')
})
Vue.filter('ymdhmsTimeFilter1',(value)=>{
  return new Date(value).format('yyyy-MM-dd hh:mm:ss')
})


// 时间处理
import moment from 'moment';
Vue.prototype.$moment = moment
moment.locale('zh-cn')
Vue.filter('time', function(value, arg){
  return moment(value).format(arg);
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
