import axios from 'axios'
import qs from 'qs'
const request = axios.create({
  baseURL: 'https://www.qyoucheng.com/webapi/',
  timeout: 5000,
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // }
});

request.interceptors.request.use(function (config) {
  console.log('接口调用前',config.url)
  // Do something before request is sent
  if (config.data) {
    config.data = qs.stringify(config.data)
  }
  // console.log('config', config)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
request.interceptors.response.use(function ({
  data
}) {
  return data;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

export default request