/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/request'
import * as api from '@/request/api'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        config: {},
    },
    mutations:{
        setConfig(state, data){
            state.config = data;
        }
    },
    actions:{
        // 获取所有企业列表
        getCompanyList({commit}){
            return new Promise((resolve,reject)=>{
                return axios.get(api.getCompanyList).then((res=>{
                    resolve(res)
                })).catch((err)=>{
                    reject(err)
                })
            })
        },
        // 模糊搜索企业
        getQueryCompany({commit},json){
            return new Promise((resolve,reject)=>{
                return axios.get(api.getQueryCompany,{
                    params:json
                }).then((res=>{
                    resolve(res)
                })).catch((err)=>{
                    reject(err)
                })
            })
        },
        // 根据分类获取企业列表
        getCompanyListByScore({commit},json){
            return new Promise((resolve,reject)=>{
                return axios.post(api.getCompanyListByScore(json.score), {
                    category_id: 14,
                }).then((res=>{
                    resolve(res)
                })).catch((err)=>{
                    reject(err)
                })
            })
        },
        // 获取企业详情
        getCompanyDetail({commit},enterpriseId){
            return new Promise((resolve,reject)=>{
                return axios.post(api.getCompanyDetail, {
                    id: enterpriseId
                }).then((res=>{
                    resolve(res)
                })).catch((err)=>{
                    reject(err)
                })
            })
        },
        // pc_about_us 关于我们 pc_agreement 协议声明 pc_disclaimer 免责声明 pc_join_us 加入我们
        getAppConfig({commit}, field){
            return new Promise((resolve, reject)=>{
                return axios.post('/index/company/getConfigByField', {
                    field,
                }).then(res=>{
                    resolve(res);
                }).catch(e=>{
                    reject(e);
                })
            })
        }
    },
})

export default store