<template>
    <!-- 企业卡片 -->
    <div class="cmp-card-wrap">
        <img :src="info.logoimage || Setting.assetsUrl + 'assets/img/logo.png'" class="logo" alt="企业logo">
        <p class="text-ell1 cmp-name">{{ info.name }}</p>
        <ul class="score-list">
            <li>
                <p class="title">企业分值</p>
                <p class="score">{{ info.score }}</p>
            </li>
            <!-- <li v-if="false">
                <p class="title">总逾期货款</p>
                <p class="score">{{info.overdueLoan}}</p>
            </li> -->
        </ul>
    </div>
</template>
<script>
import Setting from '@/setting';

export default {
    props: ['info'],
    data() {
        return {
            Setting,
        }
    }
}
</script>
<style scoped lang="less">
.cmp-card-wrap {
    width: 360px;
    height: 422px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid rgb(102, 178, 208);
    box-shadow: 0px 0px 5px 5px rgba(58, 145, 193, 0.5);

    &>.logo {
        width: 140px;
        height: 140px;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    &>.cmp-name {
        color: #333333;
        font-size: 24px;
        margin-bottom: 35px;
    }

    &>.score-list {
        width: 100%;
        padding: 0 40px;
        display: flex;
        justify-content: center;
        text-align: center;

        .title {
            color: #9D9D9D;
            font-size: 16px;
            margin-bottom: 20px;
        }

        .score {
            font-size: 30px;
            color: #FE8814;
            font-weight: bold;
        }
    }
}
</style>