<template>
  <!-- 企业简易信息 -->
  <div class="cmp-simple-info">
    <img :src="detailInfo.logoimage || Setting.assetsUrl + 'assets/img/logo.png'" class="logo" alt="企业logo"
      draggable="false">
    <div class="cmp-info">
      <div style="margin-bottom:8px">
        <span class="cmp-name">{{ detailInfo.name || '' }}</span>
        <span class="regist yes" v-if="detailInfo.state === '2'">已注册</span>
        <span class="regist no" v-if="detailInfo.state !== '2'">未注册</span>
      </div>
      <ul v-if="detailInfo.labels" class="tags clearfix">
        <li v-for="(item, index) in (detailInfo.inner_labels)" :key="index">{{ item }}</li>
      </ul>
      <ul class="score-list">
        <li>
          <p class="title">法人</p>
          <p class="legalName">{{ detailInfo.nickname }}</p>
        </li>
        <li>
          <p class="title">企业分值</p>
          <p class="score">{{ detailInfo.score }}</p>
        </li>
        <li v-if="false">
          <p class="title">总逾期货款</p>
          <p class="count">{{ detailInfo.money }}元</p>
        </li>
      </ul>
    </div>
    <div>
      <div v-if="detailInfo.category_id">
        <img src="@/assets/images/you.png" v-if="detailInfo.category_id === 14" class="flag" />
        <img src="@/assets/images/cheng.png" v-if="detailInfo.category_id === 16" class="flag" />
        <img src="@/assets/images/xu.png" v-if="detailInfo.category_id === 15" class="flag" />
        <img src="@/assets/images/chong.png" v-if="detailInfo.category_id === 19" class="flag" />
      </div>
      <!-- <div v-if="detailInfo.label_id === 19">
        <img src="@/assets/icon_quan_type_4.png" alt="" draggable="false" style="width: 90px">
      </div> -->
    </div>

  </div>
</template>
<script>
import Setting from '@/setting';
export default {
  props: ['detailInfo'],
  data() {
    return {
      Setting,
    }
  },
  mounted() {
    console.log(this.detailInfo);
  }
}
</script>
<style scoped lang="less">
.cmp-simple-info {
  width: 990px;
  height: 230px;
  padding: 0 90px 0 30px;
  display: flex;
  align-items: center;
  border: 1px solid #E4E4E4;
  background-color: #fff;
  border-radius: 10px 10px 0 0;

  .cmp-info {
    flex: 1;

    &>.score-list {
      margin-top: 20px;
      width: 370px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      font-size: 24px;
      color: #333;
      line-height: 40px;

      .title {
        color: #9D9D9D;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px;
      }

      .score {
        font-size: 36px;
        color: #FE8814;
        font-weight: bold;
      }

      .count {
        font-size: 30px;
        color: #FE8814;
        font-weight: bold;
      }
    }
  }

  .flag {
    width: 90px;
    height: 90px;
  }

  &>.logo {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    margin-right: 45px;
  }

  .cmp-name {
    font-size: 30px;
    color: #333;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  .regist {
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    padding: 0 15px;
    border-radius: 10px;
    color: #fff;

    &.yes {
      background-color: #0F83FF;
    }

    &.no {
      background-color: #666;
    }
  }

  .tags {
    &>li {
      float: left;
      margin-right: 12px;
      padding: 0 13px;
      height: 30px;
      line-height: 28px;
      border-radius: 10px;
      border: 1px solid #CCCCCC;
    }
  }
}
</style>