<template>
  <div class="content-wrap">
    <Header></Header>
    <div class="content-box">
      <ul class="breadcrumb">
        <li>
          <router-link to="/">首页</router-link>
          <span class="iconfont iconiconfontyoujiantou-copy"></span>
        </li>
        <li>
          <router-link class="active" :to="'/detail/' + id">{{
            enterpriseInfo.name
          }}</router-link>
          <span class="iconfont iconiconfontyoujiantou-copy"></span>
        </li>
      </ul>
      <div class="company-detail-wrap">
        <CmpItem v-if="!isChanged" :detailInfo="enterpriseInfo" class="compant-item"></CmpItem>
      </div>
      <div class="public-info-wrap">
        <div class="public-info-btn">企业公开信息</div>
        <ul class="public-info clearfix">
          <li>
            <span>企业法人：</span>
            <span>{{ enterpriseInfo.nickname }}</span>
          </li>
          <li>
            <span>联 系 电 话：</span>
            <span v-if="enterpriseInfo.mobileswitch">{{ enterpriseInfo.contactman }} {{ enterpriseInfo.contactmobile }}</span>
          </li>
          <br />
          <li>
            <span>企 业 地 址：</span>
            <span  v-if="enterpriseInfo.addressswitch">{{ enterpriseInfo.address }}</span>
          </li>
        </ul>
        <!-- <div class="no-data" v-else>暂无数据</div> -->
      </div>
      <div class="detail-info-wrap">
        <div @click="isShowDetail = !isShowDetail" class="public-info-btn">
          企业分值明细
          <span v-if="enterpriseRank.length > 0" class="iconfont"
            :class="[isShowDetail ? 'icontop' : 'iconbottom']"></span>
        </div>
        <ul v-if="enterpriseRank.length > 0 &&isShowDetail" class="detail-info">
          <li v-for="item in enterpriseRank" :key="item.id">
            结算时间：
            <span style="margin-right:50px" v-if="item.accountdate">
              {{ item.accountdate | time('YYYY-MM-DD') }}
            </span>
            <span v-else style="margin-right:50px">
              {{ item.createtime * 1000 | time('YYYY-MM-DD') }}
            </span>
            结算原因：<span style="margin-right:50px" class="blue-text-x">{{
              item.caption
            }}</span>
            企业分值： 
            <span v-if="item.after - item.before >= 0" class="green-text-x">+ {{ item.score }}</span>
            <span v-else class="red-text-x">- {{ item.score }}</span>
          </li>
        </ul>
        <div class="no-data" v-if="enterpriseRank.length === 0">暂无数据</div>
      </div>
      <!-- <div class="judge-info-wrap">
        <div @click="isShowJudge = !isShowJudge" class="public-info-btn">
          企业评价
          <span
            v-if="evaluation.length > 0"
            class="iconfont"
            :class="[isShowJudge ? 'icontop' : 'iconbottom']"
          ></span>
        </div>
        <ul v-if="isShowJudge && evaluation.length > 0" class="judge-info">
          <li v-for="item in evaluation" :key="item.adminEvaluationId">
            <img :src="item.logo" class="logo" />
            <div class="info-inner">
              <p class="name">{{ item.userName }}</p>
              <p class="time">{{ item.createTime }}</p>
              <p class="judge-inner">{{ item.adminEvaluationContent }}</p>
            </div>
          </li>
        </ul>
        <div class="no-data" v-if="evaluation.length === 0">暂无数据</div>
      </div> -->
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from "@/components/header";
import CmpItem from "@/components/cmpItem2";
import Footer from "@/components/footer";
import request from '../request';

export default {
  props: ["id"],
  data() {
    return {
      isShowDetail: false,
      isShowJudge: true,

      enterpriseInfo: {}, //企业信息
      enterpriseRank: [], //企业分值信息
      evaluation: [], //企业评价信息

      isChanged: false,
    };
  },
  methods: {
    //...mapActions(["getCompanyDetail"]),
  },
  async mounted() {
    window.scrollTo(0, 0);
    const x2 = await request({ url: 'api/fa_company/' + this.id, method: 'get' });
    if (x2) {
      this.enterpriseInfo = x2;
      this.enterpriseRank = x2.scores;
    }

    // this.getCompanyDetail(this.id).then(({ data }) => {
    //   this.enterpriseInfo = data;
    // });
    // this.$ajax.post('/index/company/scoreList', {
    //   id: this.id,
    //   page: 1,
    //   limit: 200,
    // }).then(res=>{
    //   console.log(res);
    //   this.enterpriseRank = res.data;
    // })
  },
  components: {
    Header,
    Footer,
    CmpItem,
  },
  watch: {
    async id(newVal) {
      this.isChanged = true;
      // this.getCompanyDetail(newVal).then(({ data }) => {
      //   this.enterpriseInfo = data.enterpriseInfo;
      //   this.enterpriseRank = data.enterpriseRank;
      //   this.evaluation = data.evaluation || [];
      //   this.isChanged = false;
      // });
      const x2 = await request({ url: 'api/fa_company/' + newVal, method: 'get' });
      if (x2) {
        this.enterpriseInfo = x2;
        this.enterpriseRank = x2.scores;
        this.isChanged = false;
      }
    },
  },
};
</script>
<style scoped lang="less">
a {
  color: #666;

  &.active {
    color: #000;
  }
}
.green-text-x{
  color: #0ba655;
}
.red-text-x{
  color: #e55354;
}
.blue-text-x{
  color: #490ce3;
}
.content-wrap {
  padding-top: 86px;

  .content-box {
    width: 1200px;
    margin: 0 auto;
  }

  .breadcrumb {
    display: flex;
    font-size: 18px;
    color: #666;
    margin-top: 40px;
    margin-bottom: 20px;

    &>li {
      display: flex;
      align-items: center;

      .iconiconfontyoujiantou-copy {
        font-size: 12px;
        margin: 0 6px;
      }

      &:last-child {
        .iconiconfontyoujiantou-copy {
          display: none;
        }
      }
    }
  }

  .search-cmp-num {
    width: 990px;
    height: 60px;
    border: 1px solid #e4e4e4;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    padding: 0 20px;
    line-height: 58px;
    color: #333333;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .company-detail-wrap {
    .compant-item {
      margin-bottom: 12px;
    }
  }

  .public-info-wrap,
  .detail-info-wrap,
  .judge-info-wrap {
    padding: 25px;
    background-color: #fff;
    width: 990px;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 12px;

    &>.public-info-btn {
      width: 180px;
      height: 60px;
      line-height: 60px;
      background-color: #ff7c25;
      text-align: center;
      color: #fff;
      margin-right: 30px;
      cursor: pointer;

      &>.iconfont {
        color: #fff;
        font-size: 18px;
        margin: 0 0 0 10px;
      }
    }

    &>.public-info>li {
      color: #000000;
      font-size: 18px;
      float: left;
      margin: 10px 70px 10px 0;
    }

    &.block {
      display: block;
      padding: 25px 0 25px 25px;
    }
  }

  .detail-info-wrap,
  .judge-info-wrap {
    display: block;
    padding: 25px 0 25px 25px;

    .detail-info {
      &>li {
        padding: 15px 0;
        border-bottom: 1px solid #bfbfbf;

        &:last-child {
          border-bottom: 0;
          padding: 15px 0 0 0;
        }
      }
    }
  }

  .judge-info-wrap {
    .judge-info {
      &>li {
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid #bfbfbf;

        &:last-child {
          border-bottom: 0;
          padding: 30px 0 15px 0;
        }

        .logo {
          width: 68px;
          height: 68px;
          border-radius: 100%;
          background-color: #bfbfbf;
          margin-right: 18px;
        }

        .info-inner {
          flex: 1;
          line-height: 1;
          font-size: 24px;
          color: #535353;

          .judge-inner {
            line-height: 1.2;
          }

          .time {
            font-size: 18px;
            color: #bfbfbf;
            margin: 15px 0;
          }
        }
      }
    }
  }

  .no-data {
    width: 100%;
    padding: 20px 20px 20px 0;
    text-align: center;
    color: #999;
  }
}
</style>
