<template>
    <div class="content-wrap">
        <Header></Header>
        <div class="content-box clearfix">
            <ul class="list">
                <li @click="changeIndex(index)" v-for="(item,index) in list" :key="index">{{item.title}}</li>
            </ul>
            <div class="info">
                <div class="title" v-html="list[index].title"></div>
                <div class="content" v-html="list[index].content"></div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from '@/components/header'
import Footer from '@/components/footer'
import Setting from '@/setting';

export default {
    props:['id'],
    data(){
        return{
            list:[
                {
                    title:'关于我们',
                    content:`
   <p>有诚App是由东莞市澳信网络服务有限公司开发、打造、创建的一款适用于手机平台载体的软件。涉及有诚APP的知识产权以及相关权利均属于东莞市澳信网络服务有限公司。</p>
<br><p>几年来，随着经济状况的走向，在市场经济的大环境下，市面上存在很大量的关于货款欠款、恶意拖欠，甚至企业主欠款逃匿的情况。该情况反映的是市面上有关交易、合作诚信的缺失，然而，目前并没有一个有效、透明的途径或方式能直接、直观反映前述交易合作诚信缺失的情况。</p>
<br><p>   本软件着重尝试提供前述的途径、打造相应的方式平台。着重针对市面上各种公司企业主体之间买卖、合作等交易关系所产生的货款进行公示。客户通过有诚App软件，可注册创建自身的账户，并登记自身的企业信息，后可在有诚APP平台上，向交易、合作相对方发送债务催收提示、发起催款流程。有诚APP平台通过客户的使用，积累各公司、企业等主体相关支付货款的情况以及相关数据，从而按照已预设公平合理的评分规则，对各公司、企业在支付货款是否如约、是否按时足额此环节上的诚信度进行评分，并对分值进行公示。</p>

<br>
<br><p>主要功能说明如下：</p>
<p>1.注册创建自身的账户，并登记自身的企业信息。</p>
<p>2.客户可通过APP平台向交易、合作相对方发送债务催收提示、发起催款流程。</p>
<p>3.在发起流程后，APP平台设置关于货款催收的告知流程、申诉流程、结束完成流程等操作节点。</p>
<p>4.按照预设的分值增扣规则，进行分值的评定、诚信等级的评价以及公示。分值的评定、诚信等级的评价以及公示情况作为APP平台的数据。同时，本APP软件亦设置相关的规则，避免注册用户进行对自身刷分加分以及恶意、虚假地针对合作企业进行扣分。</p>
                    `
                },
                {
                    title:'协议声明',
                    content:`<a href='${Setting.xieyi_doc}'>用户服务协议（有诚）</a>`
                }
            ],
            index:0
        }
    },
    mounted(){
        if(this.id){
            this.index = this.id
        }
        if(this.$route.params.id){
            this.index = this.$route.params.id;
        }
        this.getAppConfig('pc_about_us').then(res=>{
            this.list[0].content = res.data;
        });
        this.getAppConfig('pc_agreement').then(res=>{
            this.list[1].content = res.data;
        });
        window.scrollTo(0,0);
    },
    methods:{
        ...mapActions(["getAppConfig"]),
        changeIndex(i){
            this.index = i
        }
    },
    components:{
        Header,Footer
    }
}
</script>
<style scoped lang="less">
.content-wrap{
    padding-top: 86px;
    .content-box{
        width: 1200px;
        margin: 25px auto;
        color: #333333;
        font-size: 24px;
        .list{
            list-style: none !important;
            float: left;
            width: 225px;
            background-color: #fff;
            border: 1px solid #E4E4E4;
            border-radius: 10px 0 0 0;
            padding: 10px 0 10px 30px;
            margin-right: 16px;
            &>li{
                margin: 30px 0;
                line-height: 1;
                cursor: pointer;
            }
        }
        .info{
            float: left;
            width: 840px;
            border-radius: 0 10px 0 0;
            background-color: #fff;
            min-height: 350px;
            padding: 30px;
            font-size: 18px;
            .title{
                margin-bottom: 25px;
                font-size: 24px;
                font-weight: bold;
            }
            .content{
                line-height: 1.4;
                text-indent: 2em;
            }
            p{
                line-height: 1.4;
                text-indent: 2em;
            }
        }
    }
}
</style>