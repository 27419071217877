<template>
    <div class="content-wrap">
        <Header>隐私政策</Header>
        <div class="content-box clearfix">
            <div class="info">
                <div class="title" v-html="list[0].title"></div>
                <div class="content" v-html="list[0].content"></div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from '@/components/header'
import Footer from '@/components/footer'
import Setting from '@/setting';

export default {
    props:['id'],
    data(){
        return{
            list:[
                {
                    title:'有诚隐私政策',
                    content:`
 <p>尊敬的用户（在本政策中简称“您”）</p>
  <br><p>我们十分重视个人信息的保护，我们将通过有诚隐私政策（以下简称“本政策”）帮助您了解我们如何收集、使用、存储及处理您的个人信息以及保障您的个人权利。</p>
 <br>
 <h3>引言</h3>
 <br>
 <p>「有诚」（本政策中简称“本产品”）是一款由「东莞市澳信网络服务有限公司」（在本政策中简称“我们”）自主研发和运营的专注于建立、管理信用分值体系的平台产品。请您在使用本产品前，仔细阅读本政策。我们希望通过本政策向您说明在您浏览本产品、注册账号或者在本产品上进行相关活动时，我们如何按照法律法规要求处理这些数据，包括我们如何进行收集、保存、使用、存储、处理、共享、转让、公开披露等数据处理环节中的相关行为。我们将在您勾选“同意”后为您提供服务。您勾选同意或您使用本产品的行为均将视为您授权并同意我们按照本政策的指引处理您提供的数据。</p>
 <br>
 <p>如果您因年龄、智力等因素而不具有完全民事行为能力，请在您的监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本政策。</p>
 <br>
 <p>原则上，我们不向中国大陆地区以外的用户提供服务，如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。</p>
 <br><p>本政策与您所使用的我们的服务息息相关。我们希望您能够仔细阅读，并在需要时按照本政策的指引做出您认为适当的选择。本政策中涉及的相关技术词汇，我们尽量以通俗易懂、简明扼要的表述向您解释。为了使您充分理解本政策，与您的权益存在或可能存在重大关系的条款，我们已采用加粗字体提醒您注意。</p>
 <br><p>本政策的适用范围</p>
 <p>本政策适用于您所浏览或使用的本软件平台产品。</p>
 <p>本政策的生效及修改</p>
 <p>本政策一经公布即生效。一旦您确认注册账号等使用我们提供的产品服务时，都表示您已充分理解并同意我们按照本政策收集、存储、使用、处理与本产品相关的数据信息。</p>
 <br><p>基于新出台的法律法规要求或优化产品服务所必须，我们可能随时修改本政策的某些条款，该等修改构成本政策的一部分。若您不同意新的政策内容，您可停止使用本产品。</p>
 <br><h3>定义</h3>
 <br><p>您，在本政策中主要指我们的用户，即为使用本有诚平台的用户，包含数据创建者、数据发布者和数据填写者。</p>
 <p>数据，是指我们为您提供服务中涉及的数据，在本政策中将根据上下文可能指您主动提供的数据、我们在为您服务过程中产生的数据、第三方向我们提供的与您相关的合法授权的数据及公开渠道合法获取的数据，这些数据都将用于优化我们的产品向您提供的更优质的服务。</p>
 <p>个人信息，是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
 <p>个人敏感信息，是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、特定身份、金融账户等信息，以及不满十四周岁未成年人的个人信息。</p>
 <p>去标识化，是指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</p>
 <p>匿名化，是指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
 <br><h3>正文</h3>
 <br><p>我们按照合法、正当、必要、诚信、公开、透明的原则处理数据。</p>
 <p>我们收集的数据：</p>
 <p>在您首次登录有诚产品前，应先进行账号注册，填写包括但不限于个人信息、公司信息、联系方式、手机号、邮箱及昵称等信息，这是您登录和使用本产品的必要条件。</p>
 <p>若您通过第三方帐号登录、关联本产品的，如微博、微信、QQ、企业微信等，我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，获取并使用您通过第三方授权共享的个人信息如头像、昵称，并在您同意本个人信息保护政策后将您的第三方账户与您的本软件帐号绑定，使您可以通过第三方帐号直接登录并使用我们的产品与/或服务。</p>
 <p>未经其授权，我们不会超越法律法规和本政策授权范围来收集、使用、传输、处理、共享您的个人信息。您有权拒绝提供个人信息，但可能无法正常使用相应的服务、功能或者无法达到服务拟达到的效果。</p>
 <br><p>我们如何储存收集的数据：</p>
 <p>原则上，我们在中华人民共和国境内运营中收集和产生的数据将按照法律法规和国家标准规定存储于中国境内，不会向境外转移。</p>
 <p>我们将采用安全措施存储数据，数据的存储期限严格按照法律及相关法规规定，最低期限不少于 6 个月，并在满足服务目的后的合理期限内予以删除或匿名化处理，除非需要延长保留期或受到法律的允许。</p>
 <br><p>我们如何使用收集的数据：</p>
 <p>我们严格遵守法律法规的规定及与您的约定，将收集的信息用于以下用途。如根据业务及功能必要性确需改变信息收集和使用的目的，我们将再次向您进行说明，并重新征得您的同意：</p>
 <p>我们会根据本政策的约定并为实现我们的服务或功能对所收集的您的个人信息进行使用；</p>
 <p>为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；</p>
 <p>根据法律法规或监管要求向相关部门进行报告；</p>
 <p>当我们展示您的信息时，我们会采用包括内容替换、匿名化处理方式对您的个人信息进行脱敏，以保护您的信息安全。</p>
 <br><p>以下情形中，我们处理您的个人信息无需征得您的授权同意：</p>
 <p>为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</p>
 <p>为履行法定职责或者法定义务所必需；</p>
 <p>为应对政府行为，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
 <p>为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</p>
 <p>依照本法规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
 <p>法律、行政法规规定的其他情形。</p>
 <br><p>我们如何共享、转让或公开披露收集的数据：</p>
 <p>我们遵照法律法规的规定，对个人信息的共享进行严格的限制，原则上我们不会主动对外共享您的个人信息，也不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
 <p>在获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
 <p>在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
 <br><p>我们高度重视数据安全保障：</p>
 <p>数据的安全与合规管理是我们非常重视的工作，我们遵守法律法规的要求，采取较高的行业标准并实施更为高等级的安全及加密策略。 我们根据用户数据收集相关的法律法规、采用业内通行的，合理的标准进行数据收集，使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护我们所收集的数据的安全性和保密性。 我们的信息安全系统已进行国家网络安全等级保护测评（三级），并建立了完善的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。</p>
 <br><h3>您的权利</h3>
 <p>在您使用本产品期间，您可以对您提供的账户信息进行操作，如访问、更正或注销账号，在PC环境下，您可在账户设置中点击注销账号，在app环境，您可在系统设置中注销账号，通过引导前往PC环境完成账号的注销，当您完成操作后账号立即注销。如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们服务时所产生的个人信息，或您认为我们存在任何违反法规或您关于个人信息的收集使用约定，您均可以与我们联系，我们将在15个工作日内回复。</p>
 <p>请注意，如果对查看、修改和删除您的账户信息或数据有任何疑问，请联系我们，我们也可以协助您处理。</p>
 <br><p>本政策的适用及更新</p>
 <p>本产品所有服务均适用本政策。</p>
 <p>若发生公司变更、对您的信息收集及使用目的变更、您的管理方式变更、投诉机制及联络方式变更等发生重大变化情形时，我们会适时对本政策进行更新，并通过不同渠道向您发送变更通知，包括但不限于网站公示、私信通知、推送等方式。</p>
 <br><h3>联系我们</h3>
 <br><p>如果您有任何问题、建议或投诉，请与我们联系。我们将尽快审核所涉问题，并在验证您的用户身份后的24小时内予以回复。</p> 
 <br><p>联系邮箱：aoxinyoucheng@outlook.com</p>
                    `
                },
                {
                    title:'协议声明',
                    content:`<a href='${Setting.xieyi_doc}'>用户服务协议（有诚）</a>`
                }
            ],
            index:0
        }
    },
    mounted(){
        if(this.id){
            this.index = this.id
        }
        if(this.$route.params.id){
            this.index = this.$route.params.id;
        }
        this.getAppConfig('pc_about_us').then(res=>{
            this.list[0].content = res.data;
        });
        this.getAppConfig('pc_agreement').then(res=>{
            this.list[1].content = res.data;
        });
        window.scrollTo(0,0);
    },
    methods:{
        ...mapActions(["getAppConfig"]),
        changeIndex(i){
            this.index = i
        }
    },
    components:{
        Header,Footer
    }
}
</script>
<style scoped lang="less">
.content-wrap{
    padding-top: 86px;
    .content-box{
        width: 1200px;
        margin: 25px auto;
        color: #333333;
        font-size: 24px;
        .list{
            list-style: none !important;
            float: left;
            width: 225px;
            background-color: #fff;
            border: 1px solid #E4E4E4;
            border-radius: 10px 0 0 0;
            padding: 10px 0 10px 30px;
            margin-right: 16px;
            &>li{
                margin: 30px 0;
                line-height: 1;
                cursor: pointer;
            }
        }
        .info{
            float: left;
            width: 840px;
            border-radius: 0 10px 0 0;
            background-color: #fff;
            min-height: 350px;
            padding: 30px;
            font-size: 18px;
            .title{
                margin-bottom: 25px;
                font-size: 24px;
                font-weight: bold;
            }
            .content{
                line-height: 1.4;
                text-indent: 2em;
            }
            p{
                line-height: 1.4;
                text-indent: 2em;
            }
        }
    }
}
</style>