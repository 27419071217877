<template>
  <!-- 企业简易信息 -->
  <div class="cmp-simple-info">
    <img
      :src="info.logoimage || Setting.assetsUrl + 'assets/img/logo.png'"
      class="logo"
      alt="企业logo"
    />
    <div class="cmp-info">
      <div style="margin-bottom: 8px">
        <span
          class="cmp-name"
          v-html="info && info.name"
        ></span>
        <!-- <span class="regist yes" v-if="info && info.source.status === 1"
          >已注册</span>
        <span class="regist no" v-if="info && info.source.status === 0"
          >未注册</span> -->
      </div>
      <ul v-if="info" class="tags clearfix">
        <!-- <li
          v-for="(item, index) in info && info.source.labelValues"
          :key="index"
        >
          {{ item }}
        </li> -->
      </ul>
      
      <ul v-if="detailInfo" class="tags clearfix">
        <li
          v-for="(item, index) in detailInfo && detailInfo.labelEntities"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>

      <ul class="score-list">
        <li>
          <p class="title">法人</p>
          <p class="legalName">{{ info && info.nickname }}</p>
        </li>
        <li>
          <p class="title">企业分值</p>
          <p class="score">{{ info && info.score }}</p>
        </li>
        <li v-if="false">
          <p class="title">总逾期货款</p>
          <p class="count">{{ info && info.money }}</p>
        </li>
      </ul>
    </div>
    <div>
      <div v-if="info.category">
        <img src="@/assets/images/icon_home_status_xing.png" v-if="info.category.name==='优质企业'" class="flag"/>
        <img src="@/assets/images/icon_home_status_bushi.png" v-if="info.category.name==='不诚实企业'" class="flag"/>
        <img src="@/assets/images/icon_home_status_xujia.png" v-if="info.category.name==='虚假企业'" class="flag"/>
      </div>  
      <div v-if="info.label_id===19">
        <img src="@/assets/icon_quan_type_4.png" alt="" draggable="false" style="width: 90px">
      </div>
    </div>
    
  </div>
</template>
<script>
import Setting from '@/setting';
export default {
  props: ["info", "detailInfo"],
  data() {
    return {
      Setting,
    };
  },
  mounted(){
    console.log(this.info);
  }
};
</script>
<style scoped lang="less">
.cmp-simple-info {
  width: 990px;
  height: 230px;
  padding: 0 90px 0 30px;
  display: flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  .cmp-info {
    flex: 1;
    & > .score-list {
      margin-top: 20px;
      width: 370px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      font-size: 24px;
      color: #333;
      line-height: 40px;
      .title {
        color: #9d9d9d;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 20px;
      }
      .score {
        font-size: 36px;
        color: #fe8814;
        font-weight: bold;
      }
      .count {
        font-size: 30px;
        color: #fe8814;
        font-weight: bold;
      }
    }
  }
  .flag {
    width: 90px;
    height: 90px;
  }
  & > .logo {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    margin-right: 45px;
  }
  .cmp-name {
    font-size: 30px;
    color: #333;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  .regist {
    vertical-align: middle;
    display: inline-block;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    padding: 0 15px;
    border-radius: 10px;
    color: #fff;
    &.yes {
      background-color: #0f83ff;
    }
    &.no {
      background-color: #666;
    }
  }
  .tags {
    & > li {
      float: left;
      margin-right: 12px;
      padding: 0 13px;
      height: 30px;
      line-height: 28px;
      border-radius: 10px;
      border: 1px solid #cccccc;
    }
  }
}
</style>