<template>
    <div>
        <div class="banner">
            <Header :isFixed="false"></Header>
            <div class="intro-box">
                <p class="title">企业诚信 一键查询</p>
                <p>为投资合作提供安全保障</p>
            </div>
        </div>
        <div>
            <SearchBox @search="searchCmp" class="search-box"></SearchBox>
            <div class="bbb"></div>
            <div class="company-list-wrap" v-if="goodCmpList.length > 0">
                <div class="youzhi">
                    <img src="@/assets/images/t.png" alt="优质企业" class="title">
                    <div class="tc">优质企业</div>
                </div>
                <ul class="company-list clearfix">
                    <li v-for="item in goodCmpList" :key="item.id" @click="intoDetail(item.id)">
                        <CmpCard :info="item"></CmpCard>
                    </li>
                </ul>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import Header from '@/components/header';
import Footer from '@/components/footer';
import SearchBox from '@/components/searchBox';
import CmpCard from '@/components/cmpCard';
import request from '../request';

export default {
    data() {
        return {
            goodCmpList: [],     //优质企业列表
        }
    },
    async mounted() {
        window.scrollTo(0, 0);
        const x2 = await request({ url: 'api/fa_company/top-t/3', method: 'get' });
        if (x2) {
            if (x2.length <= 3) {
                this.goodCmpList = x2
            } else {
                this.goodCmpList = x2.slice(0, 3);
            }
        }

        // this.getCompanyListByScore({}).then(({ code, data }) => {
        //     if (code === 1) {
        //         if (data.length <= 3) {
        //             this.goodCmpList = data
        //         } else {
        //             this.goodCmpList = data.slice(0, 3);
        //         }
        //     }
        // })
    },
    methods: {
        ...mapActions(["getCompanyListByScore", "getAppConfig"]),
        searchCmp(name) {
            if (!name.trim()) {
                this.$message.error('请输入企业名称/代码号/标签');
                return;
            }
            this.$router.push(`list/${name}`)
        },
        intoDetail(id) {
            this.$router.push(`/detail/${id}`)
        }
    },
    components: {
        Header, Footer, SearchBox, CmpCard
    }
}
</script>
<style scoped lang="less">
.banner {
    width: 100%;
    height: 620px;
    background: url('~@/assets/images/banner.png') no-repeat center center/cover;
    position: relative;
    z-index: 1;

    &>.intro-box {
        padding-top: 210px;
        color: #fff;
        text-align: center;
        font-size: 30px;

        &>.title {
            font-size: 48px;
            margin-bottom: 30px;
        }
    }
}

.search-box {
    margin: -190px auto 0;
    position: relative;
    z-index: 3;
}

.company-list-wrap {
    width: 1200px;
    margin: 0 auto;

    .youzhi {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-align: center;

        &>.title {
            // display: block;
            margin: 20px;
            width: 50px;
            height: 50px;
        }

        &>.tc {
            font-size: 30px;
            color: rgb(37, 106, 209);
        }
    }



    &>.company-list {
        &>li {
            float: left;
            margin-right: 60px;
            margin-bottom: 30px;
            cursor: pointer;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:hover {
                box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
            }
        }
    }
}

.bbb {
    margin: 10px;
}
</style>